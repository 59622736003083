<template>
  <n-layout>
    <n-layout-content content-style="margin: 15px">
      <n-result
          :status="error.statusCode.toString()"
          :title="messages[error.statusCode].title"
          :description="messages[error.statusCode].description"
      />
    </n-layout-content>
  </n-layout>
</template>

<script setup lang="ts">
import { NResult, NLayout, NLayoutContent } from 'naive-ui'
const { error } = defineProps<{
  error: {
    message: string
    statusMessage: string
    statusCode: number
  }
}>()
console.log("Error page: ", error)
const messages = {
  404: {
    title: 'Page not found',
    description: 'Error 404: Page not found...but don\'t worry, we\'re still here!'
  },
  403: {
    title: 'Forbidden',
    description: 'Some of the doors are always close to you.'
  },
  500: {
    title: 'Internal server error',
    description: 'Looks like we hit a snag. Our team of tech experts is on it!'
  }
}
</script>