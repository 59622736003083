import {initializeApp} from "@firebase/app";
import {getAuth, onAuthStateChanged, onIdTokenChanged} from "@firebase/auth";
import {useAuthStore} from "~/store/auth";

export default defineNuxtPlugin(nuxtApp => {
    const {firebaseAppId, firebaseApiKey, project} = useRuntimeConfig().public
    const firebaseConfig = {
        apiKey: firebaseApiKey,
        authDomain: `${project}.firebaseapp.com`,
        projectId: project,
        appId: firebaseAppId
    }
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app)
    const authStore = useAuthStore()
    onAuthStateChanged(auth, authStore.authStateChanged )
    onIdTokenChanged(auth, authStore.idTokenChanged)
    nuxtApp.vueApp.provide('auth', auth)
    nuxtApp.provide('auth', auth)
})
