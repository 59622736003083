import {FetchOptions} from "ofetch";
import { UseFetchOptions} from "#app";

export const usePublicApi = <DataT>(endpoint: string , options: UseFetchOptions<DataT> = {}) => {
    const apiUrl = useRuntimeConfig().public.apiUrl + '/public' + endpoint
    options = {
        ...options,
        method: options.method || "GET",
    }
    return useFetch(
        apiUrl,
        options,
    )
}