import validate from "/opt/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/opt/app/middleware/analytics.global.ts";
import auth_45global from "/opt/app/middleware/auth.global.ts";
import redirects_45global from "/opt/app/middleware/redirects.global.ts";
import manifest_45route_45rule from "/opt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  auth_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  login: () => import("/opt/app/middleware/login.ts")
}