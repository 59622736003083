export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.hook('apollo:auth', ({ token }) => {
        const { storefrontToken } = useRuntimeConfig().public
        token.value = storefrontToken
    })
    nuxtApp.hook('apollo:error', ({ operation, networkError, graphQLErrors  }) => {
        console.error("Apollo error")
        console.error("Operation: ",operation)
        console.error("Network error:", networkError?.message)
        console.error("graphQL error: ", graphQLErrors)
        console.log("Config ", useRuntimeConfig())
    })
})
