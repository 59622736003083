import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
    const { project, disableSentry, sentryRelease: release, sentryEnv: environment } = nuxtApp.$config.public
    if (disableSentry) return

    Sentry.init({
        app: nuxtApp.vueApp,
        release,
        debug: true,
        environment,
        dsn: "https://04d1e3895cbf4dcaab850c770138f8a9@o4505171571572736.ingest.sentry.io/4505171574652928",
        integrations: [
            new Sentry.Replay({
                    networkDetailAllowUrls: [/.*flyp.space.*/, /.*.myshopify.com\/api\/.*/],
                    maskAllText: false,
                }
            )
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0,
        trackComponents: true,
        timeout: 500
    })
})