export default defineNuxtRouteMiddleware(async (to) => {
    const redirects = {
        shipping: '/docs/shipping',
        returns: '/docs/returns',
        contact: '/docs/contact'
    }
    const {shophandle: pageName} = to.params
    const redirectUrl = redirects[pageName]
    if (redirectUrl) {
        return navigateTo(redirectUrl)
    }
    const { path } = to
    const productPageRegex = /\/u\/([^/.]+)\/?([^/.]+)?/
    const match = path.match(productPageRegex)
    const [fullMatch, userHandle, productHandle, ...rest] = match || []
    if (userHandle && productHandle) {
        return navigateTo(`/${userHandle}/${productHandle}` )
    }
    else if (userHandle) {
        return navigateTo(`/${userHandle}`)
    }
})
