export default defineNuxtRouteMiddleware(async (to) => {
    const { $analytics } = useNuxtApp()
    if (!$analytics) return

    const { uid } = to.query
    if (uid) {
        const { data: user, error } = await usePublicApi(
            '/users/' + uid,
            {
                transform: (result: any) => ({
                    handle: result.handle
                })
            }
        )
        const handle = error.value ? undefined : user.value.handle
        $analytics.identify(uid, {handle})
    }
    $analytics.page(to.fullPath)
})