
export const useAuthStore = defineStore('auth',{
    state: () => ({
        currentUser: null,
        accessToken: null,
    }),
    getters: {
        isAuthenticated(): boolean {
            return this.currentUser !== null
        }
    },
    actions: {
        async authStateChanged (user) {
            this.currentUser = user
        },
        async idTokenChanged (user) {
            if (user) {
                this.accessToken = await user.getIdToken()
            } else {
                this.accessToken = null
            }
        },
        async logout () {
            const nuxtApp = useNuxtApp()
            await nuxtApp.$auth.signOut()
            this.currentUser = null
            this.accessToken = null
            useRouter().push('/login')
        },
    },
    persist: {
        storage: persistedState.localStorage,
    },
})