import Analytics from "analytics";
import googleAnalytics from '@analytics/google-analytics'
import mixpanelPlugin from "@analytics/mixpanel"

export default defineNuxtPlugin(nuxtApp => {
    const { refName, gtagId, mixpanelToken } = useRuntimeConfig().public
    const debug = process.env.NODE_ENV !== 'production'

    const analytics = Analytics(
        {
            app: 'shop',
            version: refName,
            debug,
            plugins: [
                googleAnalytics({
                    measurementIds: [gtagId],
                    debug
                }),
                mixpanelPlugin({
                    token: mixpanelToken,
                    pageEvent: 'Shop Page View',
                    name: 'shop',
                    debug
                })
            ]
        }
    )

    return {
        provide: {
            analytics
        }
    }

})