import {useAuthStore} from "~/store/auth";

export default defineNuxtRouteMiddleware((to, from) => {
    const privateRoutes: string[] = [
        'profile',
    ]
    if (to.name === undefined || to.name === null || !privateRoutes.includes(to.name.toString())) return
    const { isAuthenticated } = useAuthStore()
    if (!isAuthenticated && to.name !== 'login') {
        console.log("Not authenticated, redirecting to /login")
        return navigateTo('/login')
    }
    if(isAuthenticated && to.name === 'login') {
        console.log("Authenticated, redirecting to /profile")
        return navigateTo('/profile')
    }
})