import { default as _91product_45handle_93bRuoL07WJFMeta } from "/opt/app/pages/[shop-handle]/[product-handle].vue?macro=true";
import { default as indexCL5WY5DdHoMeta } from "/opt/app/pages/[shop-handle]/index.vue?macro=true";
import { default as cart2VojXMHHDKMeta } from "/opt/app/pages/cart.vue?macro=true";
import { default as dataJuermC0KjDMeta } from "/opt/app/pages/checkout/data.vue?macro=true";
import { default as payment8SjTY07sIZMeta } from "/opt/app/pages/checkout/payment.vue?macro=true";
import { default as shippingwUGx3lPoNnMeta } from "/opt/app/pages/checkout/shipping.vue?macro=true";
import { default as contactpfwlafObpkMeta } from "/opt/app/pages/docs/contact.vue?macro=true";
import { default as returnsB8SPRumkiUMeta } from "/opt/app/pages/docs/returns.vue?macro=true";
import { default as shippingLUcLcA3tuXMeta } from "/opt/app/pages/docs/shipping.vue?macro=true";
import { default as logindvgU5V5iouMeta } from "/opt/app/pages/login.vue?macro=true";
import { default as completeE0VXk3vGekMeta } from "/opt/app/pages/onboarding/payins/complete.vue?macro=true";
import { default as indexjucCBf9IQzMeta } from "/opt/app/pages/onboarding/payins/index.vue?macro=true";
import { default as payoutszqJFHafuc4Meta } from "/opt/app/pages/onboarding/payouts.vue?macro=true";
import { default as successTEwYoblivTMeta } from "/opt/app/pages/onboarding/success.vue?macro=true";
import { default as tiktokWsrx3wI8ghMeta } from "/opt/app/pages/onboarding/tiktok.vue?macro=true";
import { default as _91handle_93UFbwbaPPO0Meta } from "/opt/app/pages/products/[handle].vue?macro=true";
import { default as profileSfD2ljIChsMeta } from "/opt/app/pages/profile.vue?macro=true";
import { default as _91orderId_93s6695apV3dMeta } from "/opt/app/pages/success/[orderId].vue?macro=true";
import { default as indexB9qw4AcqP2Meta } from "/opt/app/pages/success/index.vue?macro=true";
export default [
  {
    name: _91product_45handle_93bRuoL07WJFMeta?.name ?? "shophandle-producthandle",
    path: _91product_45handle_93bRuoL07WJFMeta?.path ?? "/:shophandle()/:producthandle()",
    meta: _91product_45handle_93bRuoL07WJFMeta || {},
    alias: _91product_45handle_93bRuoL07WJFMeta?.alias || [],
    redirect: _91product_45handle_93bRuoL07WJFMeta?.redirect,
    component: () => import("/opt/app/pages/[shop-handle]/[product-handle].vue").then(m => m.default || m)
  },
  {
    name: indexCL5WY5DdHoMeta?.name ?? "shophandle",
    path: indexCL5WY5DdHoMeta?.path ?? "/:shophandle()",
    meta: indexCL5WY5DdHoMeta || {},
    alias: indexCL5WY5DdHoMeta?.alias || [],
    redirect: indexCL5WY5DdHoMeta?.redirect,
    component: () => import("/opt/app/pages/[shop-handle]/index.vue").then(m => m.default || m)
  },
  {
    name: cart2VojXMHHDKMeta?.name ?? "cart",
    path: cart2VojXMHHDKMeta?.path ?? "/cart",
    meta: cart2VojXMHHDKMeta || {},
    alias: cart2VojXMHHDKMeta?.alias || [],
    redirect: cart2VojXMHHDKMeta?.redirect,
    component: () => import("/opt/app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: dataJuermC0KjDMeta?.name ?? "checkout-data",
    path: dataJuermC0KjDMeta?.path ?? "/checkout/data",
    meta: dataJuermC0KjDMeta || {},
    alias: dataJuermC0KjDMeta?.alias || [],
    redirect: dataJuermC0KjDMeta?.redirect,
    component: () => import("/opt/app/pages/checkout/data.vue").then(m => m.default || m)
  },
  {
    name: payment8SjTY07sIZMeta?.name ?? "checkout-payment",
    path: payment8SjTY07sIZMeta?.path ?? "/checkout/payment",
    meta: payment8SjTY07sIZMeta || {},
    alias: payment8SjTY07sIZMeta?.alias || [],
    redirect: payment8SjTY07sIZMeta?.redirect,
    component: () => import("/opt/app/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: shippingwUGx3lPoNnMeta?.name ?? "checkout-shipping",
    path: shippingwUGx3lPoNnMeta?.path ?? "/checkout/shipping",
    meta: shippingwUGx3lPoNnMeta || {},
    alias: shippingwUGx3lPoNnMeta?.alias || [],
    redirect: shippingwUGx3lPoNnMeta?.redirect,
    component: () => import("/opt/app/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: contactpfwlafObpkMeta?.name ?? "docs-contact",
    path: contactpfwlafObpkMeta?.path ?? "/docs/contact",
    meta: contactpfwlafObpkMeta || {},
    alias: contactpfwlafObpkMeta?.alias || [],
    redirect: contactpfwlafObpkMeta?.redirect,
    component: () => import("/opt/app/pages/docs/contact.vue").then(m => m.default || m)
  },
  {
    name: returnsB8SPRumkiUMeta?.name ?? "docs-returns",
    path: returnsB8SPRumkiUMeta?.path ?? "/docs/returns",
    meta: returnsB8SPRumkiUMeta || {},
    alias: returnsB8SPRumkiUMeta?.alias || [],
    redirect: returnsB8SPRumkiUMeta?.redirect,
    component: () => import("/opt/app/pages/docs/returns.vue").then(m => m.default || m)
  },
  {
    name: shippingLUcLcA3tuXMeta?.name ?? "docs-shipping",
    path: shippingLUcLcA3tuXMeta?.path ?? "/docs/shipping",
    meta: shippingLUcLcA3tuXMeta || {},
    alias: shippingLUcLcA3tuXMeta?.alias || [],
    redirect: shippingLUcLcA3tuXMeta?.redirect,
    component: () => import("/opt/app/pages/docs/shipping.vue").then(m => m.default || m)
  },
  {
    name: logindvgU5V5iouMeta?.name ?? "login",
    path: logindvgU5V5iouMeta?.path ?? "/login",
    meta: logindvgU5V5iouMeta || {},
    alias: logindvgU5V5iouMeta?.alias || [],
    redirect: logindvgU5V5iouMeta?.redirect,
    component: () => import("/opt/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: completeE0VXk3vGekMeta?.name ?? "onboarding-payins-complete",
    path: completeE0VXk3vGekMeta?.path ?? "/onboarding/payins/complete",
    meta: completeE0VXk3vGekMeta || {},
    alias: completeE0VXk3vGekMeta?.alias || [],
    redirect: completeE0VXk3vGekMeta?.redirect,
    component: () => import("/opt/app/pages/onboarding/payins/complete.vue").then(m => m.default || m)
  },
  {
    name: indexjucCBf9IQzMeta?.name ?? "onboarding-payins",
    path: indexjucCBf9IQzMeta?.path ?? "/onboarding/payins",
    meta: indexjucCBf9IQzMeta || {},
    alias: indexjucCBf9IQzMeta?.alias || [],
    redirect: indexjucCBf9IQzMeta?.redirect,
    component: () => import("/opt/app/pages/onboarding/payins/index.vue").then(m => m.default || m)
  },
  {
    name: payoutszqJFHafuc4Meta?.name ?? "onboarding-payouts",
    path: payoutszqJFHafuc4Meta?.path ?? "/onboarding/payouts",
    meta: payoutszqJFHafuc4Meta || {},
    alias: payoutszqJFHafuc4Meta?.alias || [],
    redirect: payoutszqJFHafuc4Meta?.redirect,
    component: () => import("/opt/app/pages/onboarding/payouts.vue").then(m => m.default || m)
  },
  {
    name: successTEwYoblivTMeta?.name ?? "onboarding-success",
    path: successTEwYoblivTMeta?.path ?? "/onboarding/success",
    meta: successTEwYoblivTMeta || {},
    alias: successTEwYoblivTMeta?.alias || [],
    redirect: successTEwYoblivTMeta?.redirect,
    component: () => import("/opt/app/pages/onboarding/success.vue").then(m => m.default || m)
  },
  {
    name: tiktokWsrx3wI8ghMeta?.name ?? "onboarding-tiktok",
    path: tiktokWsrx3wI8ghMeta?.path ?? "/onboarding/tiktok",
    meta: tiktokWsrx3wI8ghMeta || {},
    alias: tiktokWsrx3wI8ghMeta?.alias || [],
    redirect: tiktokWsrx3wI8ghMeta?.redirect,
    component: () => import("/opt/app/pages/onboarding/tiktok.vue").then(m => m.default || m)
  },
  {
    name: _91handle_93UFbwbaPPO0Meta?.name ?? "products-handle",
    path: _91handle_93UFbwbaPPO0Meta?.path ?? "/products/:handle()",
    meta: _91handle_93UFbwbaPPO0Meta || {},
    alias: _91handle_93UFbwbaPPO0Meta?.alias || [],
    redirect: _91handle_93UFbwbaPPO0Meta?.redirect,
    component: () => import("/opt/app/pages/products/[handle].vue").then(m => m.default || m)
  },
  {
    name: profileSfD2ljIChsMeta?.name ?? "profile",
    path: profileSfD2ljIChsMeta?.path ?? "/profile",
    meta: profileSfD2ljIChsMeta || {},
    alias: profileSfD2ljIChsMeta?.alias || [],
    redirect: profileSfD2ljIChsMeta?.redirect,
    component: () => import("/opt/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91orderId_93s6695apV3dMeta?.name ?? "success-orderId",
    path: _91orderId_93s6695apV3dMeta?.path ?? "/success/:orderId()",
    meta: _91orderId_93s6695apV3dMeta || {},
    alias: _91orderId_93s6695apV3dMeta?.alias || [],
    redirect: _91orderId_93s6695apV3dMeta?.redirect,
    component: () => import("/opt/app/pages/success/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexB9qw4AcqP2Meta?.name ?? "success",
    path: indexB9qw4AcqP2Meta?.path ?? "/success",
    meta: indexB9qw4AcqP2Meta || {},
    alias: indexB9qw4AcqP2Meta?.alias || [],
    redirect: indexB9qw4AcqP2Meta?.redirect,
    component: () => import("/opt/app/pages/success/index.vue").then(m => m.default || m)
  }
]